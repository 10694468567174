<template>
    <div class="home-page">
        <Header/>
        <div class="main-content">
            <img class="b-tc" src="../assets/tcbanner.png">
            <div class="title">大赛动态</div>
            <div class="p-content">
                <div class="p-header">当前位置：首页>>大赛动态>>{{type===2?'通知':'新闻'}}</div>
                <div v-for="(item, index) in list" :key="index" class="single-li z-pointer" @click="$router.push('/infoDetail?type='+type+'&id='+item.id)">
                    <div class="left blo">
                        {{item.create_time.slice(8,10)}}
                        <!-- <img :src="item.image"> -->
                        <div class="time">{{item.create_time.slice(0,7)}}</div>
                    </div>
                    <div class="right blo">
                        <div class="r-title">{{item.title}}</div>
                        <div class="r-desc">{{item.content|filterStr(130)}}</div>
                    </div>
                </div>
            </div>
            <el-pagination
                v-if="Number(total)!==0"
                style="margin-top:20px;margin-bottom:20px"
                :current-page="page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="limit"
                :total="Number(total)"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>
        
        <Footer/>
    </div>
</template>
<script>

export default {
    components:{
       
    },
    data() {
        return {
            list:[],
            total:0,
            type:2,
            page:1,
            limit:10,
        }
    },
    mounted() {
        Number(this.$route.query.type) === 2?this.type=2:this.type=3;
        this.getData();
    },
    methods: {
        async getData(){
            const res = await this.$http.get('/api/index/getNewsList',{type: this.type,page:this.page,limit: this.limit,});
            this.list = res.list;
            this.total = Number(res.total);
            console.log(res);
        },
    },
}
</script>
<style lang="scss" scoped>
.main-page{
    width: 1200px;
    margin: auto;
}
.main-content{
    width:1200px;
    margin: auto;
    min-height: 500px;
    position: relative;
    .title{
        height:89px;
        line-height: 89px;
        font-size: 28px;
        padding-left: 64px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
    }
    .b-tc{
        width:1920px;
        z-index:-1;
        position: absolute;
        left: calc(50% - 960px);
    }
    .p-content{
        width:100%;
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.17);
        padding: 0 17px 15px 17px;
        .p-header{
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #343434;
            height: 51px;
            line-height: 51px;
        }
        .single-li{
            display: flex;
            margin-bottom: 20px;
            .left{
                width: 146px;
                height: 151px;
                background: #A8B3C7;
                font-size: 88px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
                text-align: center;
                line-height: 115px;
                position: relative;
                img{
                    width: 146px;
                    height: 151px;
                }
                .time{
                    height: 30px;
                    line-height: 26px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    width: 119px;
                    box-sizing: border-box;
                    border: 2px solid #FFFFFF;
                    border-radius: 10px;
                    position: absolute;
                    z-index: 99;
                    bottom: 11px;
                    left: 14px;
                    text-align: center;
                }
            }
            .right{
                width: calc(100% - 146px);
                height: 151px;
                background: #F5F7FA;
                padding-left: 40px;
                padding-top: 22px;
                box-sizing: border-box;
                .r-title{
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #343434;
                }
                .r-desc{
                    margin-top: 19px;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #343434;
                    line-height: 26px;
                }
            }
        }
    }
}
</style>
